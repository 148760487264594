import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json";
import IntroContentEn from "./content/IntroContent.json";
import translationPt from "./locales/pt/translation.json";

function SelectLng (){
  const language = window.navigator.language
if(language === 'pt' || language === 'pt-PT' || language === 'pt-br'){
  return "pt";
}else if(language === 'en' || language === 'en-gb' ||language === 'en-us'){
  return "en"
}else{
  return "en"
}}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: SelectLng(),
    fallbackLng: "en",

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
          translations: translationEn,
          IntroContent: IntroContentEn
      },
      pt: {
        translations: translationPt,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
    
  });

export default i18n;