import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  Extra,
  LogoContainer,
  Para,
  FooterContainer,
  NotHid,
  NotHid2,
  Language,
  LanguageSwitch,
  LanguageSwitchContainer,
  Divider
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}
const Logo = () => {
  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <>
      <LogoContainer onClick={() => scrollTo("intro")} aria-label="homepage">
        <SvgIcon src="oc-logo-extended.svg" width="201px" height="75px" />
      </LogoContainer>
    </>
  );
};

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="35px" height="35px" />
      </a>
    );
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <>
      <FooterSection>
        <Container>
          <Row style={{ marginBottom: "50px" }} justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Para onClick={() => scrollTo("contact")} >
                {t("About")}
              </Para>
              {/* <Para   >
                {t("Careers & Culture")}
              </Para> */}
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Title>{t("Policy")}</Title>
              <Para onClick={() => scrollTo("contact")} >
                {t("Application Security")}
              </Para>
              <Para onClick={() => scrollTo("contact")} >
                {t("Software Principles")}
              </Para>
            </Col>
              <NotHid>
            <Col lg={4} md={4} sm={12} xs={12}>
                <Language>{t("Language")}</Language>
                <LanguageSwitchContainer>
                  <LanguageSwitch onClick={() => handleChange("en")}>
                    <SvgIcon
                      src="uk.svg"
                      aria-label="homepage"
                      width="30px"
                      height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("pt")}>
                    <SvgIcon
                      src="portugal.svg"
                      aria-label="homepage"
                      width="30px"
                      height="30px"
                    />
                  </LanguageSwitch>
                </LanguageSwitchContainer>
            </Col></NotHid>
          </Row>
          <Row justify="start" >
          <Col lg={11} md={10} sm={10} xs={10} >
              <Language>{t("Address")}</Language>
              <Para style={{width:'70%'}}onClick={() => window.open("https://maps.google.com/maps?q=40.94456856108238,-8.407893215542877", "_blank")}>Urbanização Vila Pavão<br />4540-322 Escariz, Arouca</Para>
            </Col>
            <Col lg={0} md={0} sm={2} xs={2}>
            </Col>
            <Col lg={13} md={10} sm={10} xs={12} >
              <Language>{t("Contact")}</Language>
              <Para onClick={() => window.open("mailto:originalcourtesy@originalcourtesy.com?")}>
                  originalcourtesy@<Divider/>originalcourtesy.com
              </Para>
            </Col>
            <NotHid2>
              <Col lg={6} md={6} sm={10} xs={10}>
                <Language>{t("Language")}</Language>
                <LanguageSwitchContainer>
                  <LanguageSwitch onClick={() => handleChange("en")}>
                    <SvgIcon
                      src="uk.svg"
                      aria-label="homepage"
                      width="30px"
                      height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("pt")}>
                    <SvgIcon
                      src="portugal.svg"
                      aria-label="homepage"
                      width="30px"
                      height="30px"
                    />
                  </LanguageSwitch>
                </LanguageSwitchContainer>
              </Col>
            </NotHid2>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <Logo />
            <FooterContainer>
              {/* <SocialLink
                href="https://github.com/Adrinlol/create-react-app-adrinlol"
                src="github.svg"
              />
              <SocialLink
                href="https://twitter.com/Adrinlolx"
                src="twitter.svg"
              /> */}
              <SocialLink
                href="https://www.linkedin.com/company/original-courtesy/"
                src="linkedin.svg"
              />
              {/* <SocialLink
                href="https://medium.com/@lashakakabadze/"
                src="medium.svg"
              /> 
               <a href="https://www.buymeacoffee.com/adrinlol">
                <img
                  src="https://img.buymeacoffee.com/button-api/?text=    DOWNLOAD        &emoji=🍕&slug=adrinlol&button_colour=FF5F5F&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=FFDD00"
                  alt="DOWNLOAD"
                />
              </a> */}
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
